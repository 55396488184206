.div {
    margin: 10px;
}

.p {
    margin-left: 4.5vh;
    margin-right: 4.5vh;
    font-size: 1.8vh;
    margin-bottom: 6vh;
    color: rgb(177, 177, 177);
    text-decoration: none;
  }

.h3 {
    margin-left: 4.5vh;
    margin-right: 4.5vh;
    color: rgb(177, 177, 177);
    text-decoration: none;
  }

.a {
    color: rgb(255, 0, 0);
    text-decoration: none;
  }