@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
:root {
    --white: #fff;
    --green: #9900ff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: rgb(2, 0, 0);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  color: white;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  position: fixed;
  height: 60px;
}

.logo {
  width: 140px;
  padding-top: 8px;
}

.container{
    font-family:"Nunito Sans";
    font-size: 1em;
    color: var(--white);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px 15%;
    background-color: #212121;
}

button {
    font-family:"Nunito Sans";
}

.head {
    font-family:"Nunito Sans";
    font-size: 6em;
    margin: 15px 0px;
    font-weight:bold;
}

h2 {
    font-family:"Nunito Sans";
    font-weight:bold;
}

.btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 0.7em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
}

.btn.green {
  border: 4px solid var(--green);
  color: var(--white);
}

.btn.green:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: var(--green);
  z-index: -1;
  transition: 0.2s ease;
}

.btn.green:hover {
  color: var(--white);
  background: var(--green);
  transition: 0.2s ease;
}

.btn.green:hover:before {
  width: 100%;
}


svg {
    width: 100%;
}

@media only screen and (min-width: 1000px){
  .container{
    font-family:"Nunito Sans";
    font-size: 1em;
    color: var(--white);
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12%;
    background-color: #212121;
    text-align: start;
  }

  button {
      font-family:"Nunito Sans";
  }

  .head {
      font-family:"Nunito Sans";
      font-size: 7em;
      margin: 15px 0px;
      font-weight:bold;
  }

  h2 {
      font-family:"Nunito Sans";
      font-weight:bold;
  }

  .btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 0.7em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;
  }
  
  .btn.green {
    border: 4px solid var(--green);
    color: var(--white);
  }
  
  .btn.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: var(--green);
    z-index: -1;
    transition: 0.2s ease;
  }
  
  .btn.green:hover {
    color: var(--white);
    background: var(--green);
    transition: 0.2s ease;
  }
  
  .btn.green:hover:before {
    width: 100%;
  }  

  svg {
      width: 300%;
  }
}

@media only screen and (min-width: 1300px){
  .container{
    font-family:"Nunito Sans";
    font-size: 1em;
    color: var(--white);
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 15%;
    background-color: #212121;
    text-align: start;
  }

  button {
      font-family:"Nunito Sans";
  }

  .head {
      font-family:"Nunito Sans";
      font-size: 7.5em;
      margin: 15px 0px;
      font-weight:bold;
  }

  h2 {
      font-family:"Nunito Sans";
      font-weight:bold;
  }

  .btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;
  }
  
  .btn.green {
    border: 4px solid var(--green);
    color: var(--white);
  }
  
  .btn.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: var(--green);
    z-index: -1;
    transition: 0.2s ease;
  }
  
  .btn.green:hover {
    color: var(--white);
    background: var(--green);
    transition: 0.2s ease;
  }
  
  .btn.green:hover:before {
    width: 100%;
  }  

  svg {
      width: 120%;
  }
}