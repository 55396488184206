html, body, #app, #app>div {
    height: 100vh;
    background-color: #0e0e0e;
  }
  
  .header {
    background-color: #000000;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(5vh);
    font-weight: 700;
    color: white;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    align-items: center;
    justify-content: center;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
  }
  
  .comingSoon {
    max-width: 30vh;
    height: auto;
    margin-bottom: 6vh;
  }
  
  .instagram {
    height: 4vh; 
    margin-right: 3vh;
  }
  
  .twitter {
    height: 4vh;
  }
  
  .h1 {
    margin-left: 3vh;
    margin-right: 3vh;
    font-size: 2.5vh;
    margin-bottom: 4vh;
    text-align: center;
    font-weight: 700;
  }
  
  p {
    margin-left: 4.5vh;
    margin-right: 4.5vh;
    font-size: 1.8vh;
    margin-bottom: 6vh;
  }
  
  .aura {
    flex-direction: row;
    justify-content: center;
    text-align: center;
    display: flex;
    color: white;
    font-size: 1.4vh;
    margin-top: 8vh;
  }
  
  a {
    color: white;
    text-decoration: none;
  }
  
  @media only screen and (min-width: 900px) {
    .container {
      margin-left: 10vh;
      margin-right: 10vh;
      flex-direction: row;
    }
    
    .textContainer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  
    .comingSoon {
      min-width: 31vw;
      margin-bottom: 0;
      margin-right: 6vh;
    }
    
    .h1 {
      font-size: 2.8vw;
      margin-bottom: 5vh;
      text-align: left;
      font-weight: 700;
      margin-inline-start: 0;
    }
    
    p {
      font-size: 3vh;
      text-align: left;
      margin-bottom: 5vh;
      margin-inline-start: 0;
    }
  
    .instagram {
      height: 6vh;
      margin-right: 0;
    }
    
    .twitter {
      height: 6vh;
      margin-bottom: 8vh;
    }
  
    .aura {
      color: white;
      display: flex;
      font-size: 1.5vh;
      margin-top: 8vh;
    }
  }
  